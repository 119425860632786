<template>
  <div class="wrapper">
    <div>
      <div class="page_container">
        <h6>Leaderboards</h6>
      </div>
      <div class="select-sport">
        <button
          :class="{ active: activeCategory === 'sports' }"
          @click="setCategory('sports')"
        >
          Sports
        </button>
        <button
          :class="{ active: activeCategory === 'casino' }"
          @click="setCategory('casino')"
        >
          Casino
        </button>
      </div>

      <div>
        <div class="leaderboard-wrapper">
          <div class="filter">
            <button @click="openLeaderboardUp">
              <span>{{ activeLeaderboard }}</span>
              <span><CaretDown /></span>
            </button>
          </div>
        </div>

        <div class="select-rules">
          <p
            :class="{ active: activeTab === 'points' }"
            @click="setActive('points')"
          >
            {{ $t("points") }}
          </p>
          <p
            :class="{ active: activeTab === 'rules' }"
            @click="setActive('rules')"
          >
            {{ $t("rules") }}
          </p>
          <p
            :class="{ active: activeTab === 'period' }"
            @click="setActive('period')"
          >
            {{ $t("period") }}
          </p>
        </div>
        <div class="page_container" v-if="!loading && leaderboard.length < 1">
          <NoData
            :title="$t('noAvailableBets')"
            :description="$t('youDontHaveBet')"
            :cta="$t('placeABet')"
            noDataTo="/"
          />
        </div>
        <div class="load page_container" v-if="loading">
          <LeaderboardLoader :count="10" />
        </div>
        <div class="ranking page_container">
          <div
            v-for="(entry, index) in leaderboard"
            :key="index"
            class="position-wrapper"
          >
            <div class="position">
              <div class="position-id">
                <div
                  :class="[
                    'number',
                    { active: [2, 4].includes(entry.position) },
                    { active2: [1].includes(entry.position) },
                  ]"
                >
                  <span>{{ entry.position }}</span>
                </div>
                <div>
                  <p>ID: {{ entry.msisdn }}</p>
                  <!-- (stake / 100) x bets -->
                  <span>{{ entry.points }} Points</span>
                  <!-- <span>{{ (entry.stake / 100) * entry.bets }} Points</span> -->
                </div>
              </div>
              <div class="cash">
                <p>{{ entry.stake }} {{ fiatCurrency }}</p>
                <span>Cash</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChopbetSlideUp
      :isOpen="isLeaderboardOpen"
      @closeLeaderboardUp="closeLeaderboardUp"
    >
      <div class="learn_more">
        <div class="filter_contents">
          <p class="filter_heading">
            <span>Leaderboard Type</span>
            <span @click="closeLeaderboardUp"><CancelIcon /></span>
          </p>
          <div
            v-for="item in leaderboardType"
            :key="item"
            class="item"
            @click="handleLeaderboard(item)"
          >
            <div>
              <div class="item_left">
                <p>{{ item.name }}</p>
              </div>
              <span v-if="activeLeaderboard === item.name">
                <TickRed />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
    <ChopbetSlideUp :isOpen="isPeriodUpOpen" @closePeriodup="closePeriodUp">
      <div class="learn_more">
        <div class="filter_contents">
          <p class="filter_heading">
            <span>Select Period Season</span>
            <span @click="closePeriodUp"><CancelIcon /></span>
          </p>
          <div v-for="item in selectPeriod" :key="item" class="item">
            <div>
              <div class="item_left">
                <p>{{ item.name }}</p>
                <span>{{ item.date }}</span>
              </div>
              <span v-if="selectedTime === item.name">
                <ArrowRight />
              </span>
              <span v-else></span>
            </div>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
    <bottom-navigation active-bets="0" page="login"></bottom-navigation>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
import BottomNavigation from "@/components/BottomNavigation";
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import CaretDown from "@/components/icons/CaretDown.vue";
import ArrowRight from "@/components/ui/ArrowRight.vue";
import CancelIcon from "@/components/icons/CancelIcon.vue";
import TickRed from "@/components/ui/TickRed.vue";
import { fetchLeaderboard } from "../../actions/leaderboard";
import LeaderboardLoader from "./LeaderboardLoader.vue";
import NoData from "@/components/ui/NoData.vue";

export default {
  name: "Leaderboard",
  components: {
    ChopbetSlideUp,
    CaretDown,
    TickRed,
    CancelIcon,
    ArrowRight,
    LeaderboardLoader,
    NoData,
    BottomNavigation,
  },
  data() {
    return {
      isPeriodUpOpen: false,
      isLeaderboardOpen: false,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      activeCategory: "sports",
      activeTab: "points",
      activeLeaderboard: "Local Leaderboard",
      leaderboardData: [
        { rank: 1, id: "001", points: 150, cash: 1000 },
        { rank: 2, id: "002", points: 120, cash: 800 },
        { rank: 3, id: "003", points: 100, cash: 600 },
        { rank: 4, id: "004", points: 80, cash: 400 },
        { rank: 5, id: "005", points: 50, cash: 200 },
      ],
      selectPeriod: [
        { id: 1, name: "Week 1", date: "Sept 1 - Sept 7" },
        { id: 2, name: "Week 2", date: "Sept 1 - Sept 7" },
        { id: 3, name: "Week 3", date: "Sept 1 - Sept 7" },
        { id: 4, name: "Week 4", date: "Sept 1 - Sept 7" },
        { id: 5, name: "Week 5", date: "Sept 1 - Sept 7" },
        { id: 6, name: "Week 6", date: "Sept 1 - Sept 7" },
        { id: 7, name: "Week 7", date: "Sept 1 - Sept 7" },
      ],
      leaderboardType: [
        { id: 1, name: "Local Leaderboard" },
        { id: 2, name: "Champions League" },
        { id: 3, name: "Premier League" },
        { id: 4, name: "La Liga" },
        { id: 5, name: "British Cup" },
      ],
      leaderboard: [],
      loading: true,
    };
  },
  methods: {
    handleLeaderboard(type) {
      this.activeLeaderboard = type.name;
      this.closeLeaderboardUp();
    },
    openLeaderboardUp() {
      this.isLeaderboardOpen = true;
    },
    closeLeaderboardUp() {
      this.isLeaderboardOpen = false;
    },
    openPeriod() {
      this.isPeriodUpOpen = true;
    },
    closePeriodUp() {
      this.isPeriodUpOpen = false;
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    async myLeaderboard() {
      const vm = this;
      var m = this.getProfile();
      var p = m.a;
      // Prevent concurrent fetches
      if (vm.is_busy) return;
      this.loading = true;

      // Check if the current page exceeds the last page
      if (parseInt(vm.page) > parseInt(vm.last_page)) {
        this.loadingGames = false;
        vm.is_busy = false;
        return;
      }
      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);
      this.loadingGames = true;
      if (!p) {
        this.$router.push({ name: "login", params: {} });
        return;
      }
      try {
        const leaderboard = await fetchLeaderboard({
          apiKey: this.getAuth(),
        });
        this.leaderboard = leaderboard;
      } catch (error) {
        this.setError(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    filteredLeaderboard() {
      if (this.activeTab === "points") {
        return this.leaderboardData;
      }
      return this.leaderboardData.map((entry) => ({
        ...entry,
        points: this.activeTab === "rules" ? "N/A" : entry.points,
        cash: this.activeTab === "period" ? "N/A" : entry.cash,
      }));
    },
  },
  mounted() {
    this.myLeaderboard();
  },
};
</script>
