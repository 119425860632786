import instance from "../services/casino";

export const fetchLeaderboard = async ({ apiKey }) => {
  const path = "/leaderboard";

  try {
    const response = await instance.get(path, {
      headers: {
        "api-key": apiKey,
      },
    });
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;
      if ([401, 400, 428].includes(status)) {
        errorMessage = `${this.$t("sessionExpired")}`;
        // router.push({ name: "login" }); // Route to login page
        // this.logout();
        // this.setError(
        //   "Session Expired",
        //   "Your session on this device has expired"
        // );
        // this.logout();
        return;
      }
    }
    throw new Error(errorMessage);
    // return errorMessage;
  }
};
